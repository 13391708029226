.admin-panel-table {
  margin-top: 20px;
  background-color: white; 
}
.admin-panel-table-icon {
  display: flex;
  align-items: center;
}
.admin-panel-table-name {
  margin: 10px;
}