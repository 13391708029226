@tailwind base;
@tailwind components;
@tailwind utilities;
.background {
    background: url('../icons/background.jpg') no-repeat center center fixed;
    min-height: 100vh;
    opacity: 1;
    visibility: inherit;
    z-index: 20;
    background-repeat: no-repeat;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.dropdown {
    display: inline-block;
    background: transparent;
    border: transparent;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0px;
}

#root > div > .container {
    min-height: 100vh;
    position: relative;
    padding-bottom: 70px;
}

/* .container > div {
    height: calc(100vh - 134px);
    overflow: auto;
} */
.container {
    max-width: 95%;
    width: 95%;
}

.alert.alert-warning {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
    position: fixed;
    z-index: 10;
    padding: 5px 25px 5px 25px;
    font-size: 20px;
    border-radius: 10px;
    left: 50%;
    margin-left: -127px;
    top: 15px;
}

.input-dropdowns {
    margin-left: 10px;
}

.blue.darken-1 {
    background-color: #2f698a !important;
}

.card {
    border-radius: 10px;
}

body {
    margin: 0;
    padding: 0;
    height: 100%;
    background: url('../icons/background.jpg') no-repeat center center fixed;
    background-size: cover;
}

/* blocker */
.disable-blocker {
  z-index: 999;
  position: absolute;
  text-align: center;
  left: 0%;
  right: 0%;
  min-height: 100vh;
  background-color: #b7b7b7ad;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disable-blocker p {
  font-size: 18px;
  color: black;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
}


/* CSS Scroll */
#scrollbar-style::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #F5F5F5;
}
#scrollbar-style::-webkit-scrollbar {
    width: 12px;
    background-color: #F5F5F5;
}
#scrollbar-style::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #2bbbad;
}


/* Header */
.container .bottom-radius {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.container .disabled-link {
    pointer-events: none;
}

.container .disabled-cursor {
    cursor: not-allowed;
}

.logo {
  line-height: 10px;
}

.logo-icon {
  height: 60px;
}


/* Footer */
footer {
    position: absolute;
    bottom: -50px;
    width: 100%;
    z-index: 10;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
footer .input-field {
    width: 150px;
    color: black;
}

.page-footer .container {
    line-height: 2;
}

.page-footer .container img {
    margin-left: 20px;
}

/* Landing  page */
.landing-component .text-wrap {
    font-size: 2.5vh;
    padding: 5px 10px 5px 10px;
    border-radius: 10px;
    color: #FFFFFF;
    background-color: #000000;
    width: 27vh;
    margin-bottom: 5px;
}
.landing-component .text-wrap.white {
    color: #333333;
    background-color: #FFFFFF;
    width: 35vh;
}
.landing-component .text-wrap.white.small {
    width: 23vh;
}
.landing-component .btn.waves-effect {
    font-size: 2.5vh;
}
.landing-component .card-container .col .card-content {
    min-height: 135px;
    /* font-size: 2vh; */
}
.landing-component .card-container .row {
    margin-bottom: 0px;
}
.landing-component .card-container,
.landing-component .text-container {
   padding-bottom:15px;
    padding-top: 5vh;
}
.landing-component .text-container {
    color: #002060;
    text-align: center;
}
.landing-component .text-container h4 > b > p {
    margin: 10px;
}
.landing-component .button-wrap {
    margin-top: 35px;
}

/* Guest */

/* .guest-component .card-container .col .card-content {
    height: 20vh;
    font-size: 2vh;
} */
.guest-component .card-container .row {
    margin-bottom: 0px;
}
.guest-component .card-container,
.guest-component .text-container {
    padding-top: 8vh;
}

.guest-component .card .card-content {
    min-height: 177px;
}
.guest-component .card .card-content.funds {
    min-height: 80px;
}


/* User setting */
.user-settings {
    background-color: #ccccccb5;
    padding-bottom: 15px;
    padding-top: 20px;
    margin-bottom: 0px;
}
.user-settings .input-field > label {
    color: #000000;
}
.user-settings .avatar {
    position: absolute;
    margin-left: 11%;
}

.user-settings .avatar .circle {
    height: 100px;
}

.user-settings .square {
    width: 100px;
    height: auto;
    margin: 0 auto;
    display: block;
}

.user-settings .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
}

.user-settings textarea {
    min-width: 100%;
    max-width: 100%;
    min-height: 100px;
    border-color: rgba(169, 169, 169, 0);
    overflow:hidden;
    border-bottom: 1px solid #9e9e9e;
}

.user-settings .btn {
    border: 2px solid gray;
    color: gray;
    background-color: white;
    padding: 0px 10px;
    border-radius: 8px;
    font-size: 15px;
    font-weight: bold;
    width: 215px;
    cursor: pointer;
}

.user-settings .upload-btn-wrapper input[type=file] {
    font-size: 25px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 215px;
    cursor: pointer;
}
.user-settings .card-container .button-item {
    color: #FFFFFF!important;
}

.patient-settings textarea[disabled],
.patient-settings input[disabled] {
    color: #000000!important;
}
.patient-settings label[for="additionalInfo"] {
    top: -5px;
    color: rgba(0,0,0,0.42);
}
.patient-settings .additionalInfo {
    text-align: justify;
}


/* Terms and conditions */
.terms-and-conditions {
  padding: 20px 0px;
  font-size: 2vh;
  overflow: auto;
}

/* Dashboard */
.dashoboard-component .collection .collection-item {
    background-color: #1d88e573;
    min-height: 90px;
}

.dashoboard-component .collection-item.avatar .title {
    font-size: 22px;
}
.dashoboard-component .collection-item .btn-small {
    margin-right: 7px;
}

.dashoboard-component .right.amount {
    width: 20%;
    height: 33px;
    margin-right: 10px;
}

/* ADS container */
.ads-component {
    background-color: #ccccccb5;
}

.ads-component .input-field > label {
    color: #000000;
}
.ads-component .container-ads {
    padding-top: 15px;
    padding-bottom: 20px;
    margin-bottom: 0px;
}

/* Adv view container */
.adv-view-component {
    background-color: #ccccccb5;
}

.adv-view-component .collapsible-body {
    display: inline-block;
}

.adv-view-component .collapsible > li {

}

.adv-view-component .card.horizontal .card-image {
    width: 30%;
}

.adv-view-component .card.horizontal .valign-wrapper {
    width: 30%;
}

.adv-view-component .card.horizontal img {
    width: 100%;
    position: relative;
    left: 0px;
    max-height: 90px;
    border-radius: 10px;
}

.adv-view-component .card .card-action {
    padding: 16px 15px;
}

.adv-view-component .horizontal.seen {
    opacity: 0.5;
}

.adv-view-component .progress {
    height: 20px;
    margin-top: 20px;
    width: 100%;
}

.adv-view-component .back-button {
    margin-bottom: 10px;
    margin-top: 10px;
    width: auto;
    min-width: 205px;
    margin-left: 15px;
}

.adv-view-component .card .card-content {
    padding: 10px;
}

.adv-view-component .success {
    font-size: 40px;
    color: #159e3f;
}
.css-b62m3t-container{
  z-index: 999;
}

.adv-view-component .card.horizontal {
    height: 110px;
}

.adv-view-component .card.horizontal .card-content {
    font-size:20px;
}

.ads-component .dropdown-select .dropdown-inner__control {
    height: 90px;
    background: transparent;
    color: black;
    border-color: hsl(0, 0%, 66%);
    border: none;
    border-bottom: 1px solid #9e9e9e;
    border-radius: 0px;
}

.dropdown-select > span {
    z-index: 10;
}

.ads-component .waves-effect.waves-light {
    z-index: 0;
}

.ads-component .dropdown-inner__value-container {
    max-height: 71px;
    overflow: auto;
}

.ads-component .dropdown-inner-price {
  height: 40px;
}

.ads-component .dropdown-inner-price__value-container {
  height: auto;
}
.ads-component .buy-button {
    width: auto;
    border-radius: 5px;
    padding-left: 5px;
    padding-right: 5px;
}
/* Payments */


.payment-component .card .card-image .card-title {
    padding: 10px;
    text-shadow: 0px 0px 20px #1c6ea4;
}
.payment-component .card-content .input-field {
    margin-top: 0px;
}
.card-content .input-field .amount {
    /* height: 20px; */
}
.card {
    /* min-height: 265px; */
}

.payment-card {
  min-height: 265px;
}

.card .card-image img {
    height: 191px;
}


.rdtPicker td,
.rdtPicker th {
  padding: 4px 5px;
}
/*Datetime*/
/*!
 * https://github.com/YouCanBookMe/react-datetime
 */

.rdt {
  position: relative;
}
.rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 4px;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 1px 3px rgba(0,0,0,.1);
  border: 1px solid #f9f9f9;
}
.rdtOpen .rdtPicker {
  display: block;
}
.rdtStatic .rdtPicker {
  box-shadow: none;
  position: static;
}

.rdtPicker .rdtTimeToggle {
  text-align: center;
}

.rdtPicker table {
  width: 100%;
  margin: 0;
}
.rdtPicker td,
.rdtPicker th {
  text-align: center;
  height: 28px;
}
.rdtPicker td {
  cursor: pointer;
}
.rdtPicker td.rdtDay:hover,
.rdtPicker td.rdtHour:hover,
.rdtPicker td.rdtMinute:hover,
.rdtPicker td.rdtSecond:hover,
.rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer;
}
.rdtPicker td.rdtOld,
.rdtPicker td.rdtNew {
  color: #999999;
}
.rdtPicker td.rdtToday {
  position: relative;
}
.rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #428bca;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.rdtPicker td.rdtActive,
.rdtPicker td.rdtActive:hover {
  background-color: #428bca;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff;
}
.rdtPicker td.rdtDisabled,
.rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}

.rdtPicker td span.rdtOld {
  color: #999999;
}
.rdtPicker td span.rdtDisabled,
.rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker th {
  border-bottom: 1px solid #f9f9f9;
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
}
.rdtPicker th.rdtSwitch {
  width: 100px;
}
.rdtPicker th.rdtNext,
.rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top;
}

.rdtPrev span,
.rdtNext span {
  display: block;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}

.rdtPicker th.rdtDisabled,
.rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed;
}
.rdtPicker thead tr:first-child th {
  cursor: pointer;
}
.rdtPicker thead tr:first-child th:hover {
  background: #eeeeee;
}

.rdtPicker tfoot {
  border-top: 1px solid #f9f9f9;
}

.rdtPicker button {
  border: none;
  background: none;
  cursor: pointer;
}
.rdtPicker button:hover {
  background-color: #eee;
}

.rdtPicker thead button {
  width: 100%;
  height: 100%;
}

td.rdtMonth,
td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer;
}
td.rdtMonth:hover,
td.rdtYear:hover {
  background: #eee;
}

.rdtCounters {
  display: inline-block;
}

.rdtCounters > div {
  float: left;
}

.rdtCounter {
  height: 100px;
}

.rdtCounter {
  width: 40px;
}

.rdtCounterSeparator {
  line-height: 100px;
}

.rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.rdtCounter .rdtBtn:hover {
  background: #eee;
}
.rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em;
}

.rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px;
}

.rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px;
}

.rdtTime td {
  cursor: default;
}

.payment-method {
  width: 200px!important;
}
.mobile-menu-icons{
  display: none;
}
.mobile-menu-icons svg{
  font-size: 40px;
}
.show-mobile-navbar{
  display: none;
  /* background: #fff; */
  background: #2f698a;
  z-index: 99999;
  position:relative;
}
.show-mobile-navbar li{
  color:#fff;
  list-style: none;
  font-size: 18px!important;
  text-align: center;
  padding:15px 0px;
  border-bottom: 1px solid  rgba(0,0,0,.1);
}
.show-mobile-navbar li a{
  color:#fff;
  font-size: 18px!important;
}
.hide-mobile-navbar{
  display: none;
}
.video-container #myId > div {
  width: 100%;
}
.mobile-search-section{
  display: none;
}
.mobile-search-icon{
  display: none;
}

.hide-mobile-search-section{
  display: none;
}
.mobile-search-icon svg{
  font-size: 15px;
}
.waves-effect{
  margin-right: 10px;
}
@media only screen and (max-width: 425px){
  span.left{
    /* gap:10px!important; */
    justify-content: space-between;
    padding-right: 20px;
  }
  .card-action .mr-4{
    margin-right:8px!important ;
  }
  .card .card-action{
    padding:16px 3px!important;
  }
  .adv-view-component .back-button{
    margin-left: 10px!important;
  }
  .btn-large{
    padding:0px 15px!important;
  }
  .row .col{
    float:none;
    width: 100%;
  }
  .patientpage{
    float:left!important;
  }
  .donation-ak{
    width: 100%!important;
  }
}
 label {
  color: #000000!important;
  font-size: 16px!important;
}
@media screen and (max-width:1024px){
  .donation-ak {
    width: 60%;
}
.user-settings .avatar
{
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  margin-left:0px!important;
}
.video-container{
  height: 900px;
}
.user-form{
  margin-top: 120px;
}
}
@media only screen and (max-width: 767px) 
{
  .adv-view-component .card.horizontal{
    height: 100%!important;
  }
  .ads-component .container-ads{
    position: relative;
    z-index: 999;
  }
  .waves-effect{
    margin-right:0px!important;
  }
  .show-mobile-search-section{
    display: block!important;
    position:absolute;
    top:60px;
    background:#2f698a;
    display: flex!important;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    z-index: 999;
  }
  #search-btn{
    display: none;
  }
  .mobile-search-icon{
    display: block!important;
  }

  .search-wrapper{
    display: none;
  }
  .page-footer {
    padding: 0px!important;
    /* z-index: -1!important; */
  }
  .page-footer{
    padding-top:0px!important;
  }
  .btn-group{
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* flex-wrap: wrap; */
  }
  .btn-group .waves-effect{
    min-width: 125px!important;
  }
  .show-mobile-navbar{
    display: block!important;
  }
  .mobile-menu-icons{
    display:block;
    display:flex;
    align-items: center;
    justify-content:center;
  }
  .dashbord-navbar{
    display: none;
  }
  .payment-component.mt-40 {
    margin-top: 30px;
}
.logo
{
  margin:0px!important;
}
nav ul a
{
  padding: 0 5px;
}
  nav ul li
  {
        font-size: 14px !important;
    line-height: 15px;
    float:none;
  }
  ul#nav-mobile {
    align-items:center;
    display: grid;
    grid-template-columns: 85% 15%;
}
span.left {
   align-items:center;
    display: flex;
    float:none!important;
}
.landing-component .text-container {
    font-size: 23px;
    color: #002060;
    text-align: center;
}
.ak-card {
    padding-top: 0px !important;
}
a.waves-effect.waves-light.btn-large {
    margin-bottom: 10px;
}

table.admin-panel-table.striped {
    display: block;
    overflow: scroll;
    width: 100%;
}

 .col .s6{
  width: 100%!important;
}
.col .s3{
  width: 100%!important;
}
.col.offset-s3{
  margin-left: 0px!important;
}
.terms-and-conditions {
    padding: 20px 0px;
    overflow: auto;
    font-size: 20px!important;
}
iframe
{
  width:100%;
}
.dashboard-ak {
    width: 100%;
}
}
